




















































































































































@import '@design';
@import '@src/design/themes/BlueGiant.scss';

$description-font-size: 1.15em;

.menu-section {
  position: relative;
  &.subsection {
    margin-top: 50px;
  }
  &.isDirty {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 5px;
      content: '';
      background: aquamarine;
      transform: translateY(-100%);
    }
  }
}

.menu-section--top {
  margin-top: 0.5em;
}

.menu-section--title {
  margin-bottom: 0.1em;
  font-size: $description-font-size * 2;
  font-weight: 300;
  line-height: 0.7;
  letter-spacing: 0.038em;

  @media (max-width: 600px) {
    position: sticky;
    top: 0;
    background-color: rgb(250, 250, 250);
  }
}

.menu-section--subtitle {
  font-size: 1.15rem;
  font-weight: 500;
}

.menu-entity--buttons-wrap.Section-buttons {
  z-index: 929;

  $distance: 30px;

  &.top {
    top: 0;
    padding-bottom: $distance;
    transform: translateY(-100%);
  }
  &.bottom {
    top: auto;
    bottom: 0;
    padding-top: $distance;
    transform: translateY(100%);
  }
}

.blue-giant--menu-item {
  .menu-item--title,
  .item-price {
    font-size: $description-font-size * 1.3;
    font-weight: 800 !important;
    line-height: 1.2 !important;
  }

  .menu-element--dots {
    height: $description-font-size * 1.3;
    border-width: 1px;
  }

  .menu-item--description {
    @extend .karla--font;

    font-size: $description-font-size;
    line-height: 1.3;
    letter-spacing: 0.02em;
  }
}
